/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { Col, Grid, Row, styled, withTheme, thd } from '@smooth-ui/core-sc';

// Additional Components/Containers
import Button from '_platform/src/components/Button/Button';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';
import BannerLogin from '../../components/CustomComponents/Banners/BannerLogin';
import IconWrapper from '_platform/src/components/SvgIcon/IconWrapper';
import { withSettings } from 'containers/WebApp/SettingsContext';

import VimeoVideoEmbed from 'components/VideoEmbed/VimeoVideoEmbed';

import RegisterIcon from './images/BOB-2023-Register-Icon@2x.png';
import EarnIcon from './images/BOB-2023-Earn-Icon@2x.png';
import BoostIcon from './images/BOB-2023-Boost-Icon@2x.png';
import EnjoyIcon from './images/BOB-2023-Enjoy-Icon@2x.png';

const PageContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;

  button,
  .register-button {
    background-color: ${thd('primary', '#fff')};
    border-radius: 100em;
    color: #fff;
    min-width: 180px;
    padding: 10px 40px;
    text-decoration: none;

    &:visited {
      color: #fff;
    }
  }
`;
const LoginPageContainer = styled.div`
  .quickview-overlay {
    background-color: ${thd('quickviewBackground', '#fff')};
    bottom: 0;
    left: 0;
    opacity: 0.85;
    //position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.3s ease 0s;
    visibility: visible;
    z-index: 999;
  }
`;

const DistributorButton = styled(Button)`
  border-radius: 100em;
  color: #fff;
  font-weight: bold;
  min-width: 180px;
  padding: 25px 50px;

  &:visited {
    color: #fff;
  }
`;

const IntroContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.65);
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  padding: 0.5rem;

  label {
    font-weight: bold;
  }

  a {
    color: #1b1918;
    &:visited {
      color: #1b1918;
    }
  }
`;

const LightGrey = styled.section`
  background-color: rgba(148, 149, 152, 0.25);
`;

const LoginPage = ({ settings }) => (
  <LoginPageContainer>
    <div className="quickview-overlay" />
    <PublicComponent
      redirectOnError={
        // Redirect to returnUrl, if it's not the same as loginPagePath
        (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
          getReturnUrlPath) ||
        '/'
      }
    >
      <Helmet>
        <title>Login</title>
        {/*<meta name="description" content="Page description" />*/}
      </Helmet>
      <BannerLogin />
      <PageContainer>
        <Grid className="page-container">
          <IntroContainer>
            <Grid>
              <Row>
                <Col xs={12} lg={6}>
                  <VimeoVideoEmbed
                    videoId="1057389388"
                    hashParameter="ce76521cb9"
                    title="Best of the Best Foodservice Rewards 2025"
                  />
                </Col>
                <Col xs={12} lg={6} className="spacer--around">
                  <h2 className="text--primary">
                    Best of the Best Rewards Program
                  </h2>
                  <p>
                    <i>Best of the Best</i> is a loyalty program designed to
                    reward you for every <i>Best of the Best</i> product you
                    purchase from your NAFDA Foodservice Distributor! With a
                    history spanning more than 25 years, <i>Best of the Best</i>{' '}
                    continues to deliver you the best products at the best
                    prices with the best rewards in the industry.
                  </p>
                  <p className="spacer--bottom">
                    <i>Best of the Best</i> Rewards Program runs from the 1st of
                    March 2025 to the 31st of January 2026. This year will
                    feature more than 1,700 bestselling products from over 70 of
                    the top foodservice suppliers. Redeem your points for a huge
                    selection of your favourite rewards including prepaid credit
                    cards, Distributor credit and retail gift cards including
                    Bunnings, JB Hi-Fi, Myer and Flight Centre!
                  </p>
                  <a
                    href="https://nafda.com.au/wp-content/uploads/2024/07/BoB24_catalogue.pdf"
                    target="_blank"
                    className="register-button"
                    rel="noopener noreferrer"
                  >
                    Download product catalogue
                  </a>
                </Col>
              </Row>
            </Grid>
          </IntroContainer>
        </Grid>
      </PageContainer>
      <LightGrey>
        <Grid className="spacer">
          <Row>
            <Col xs={6} md={3} className="text--center">
              <IconWrapper height="91px">
                <Link to="/register/">
                  <img
                    src={RegisterIcon}
                    alt="Register"
                    height="91"
                    width="91"
                  />
                </Link>
              </IconWrapper>
              <p className="text--primary text--bold">REGISTER</p>
              <p>
                Set up your
                <br />
                <strong>free online account</strong>
                <br />
                here.
              </p>
            </Col>
            <Col xs={6} md={3} className="text--center">
              <IconWrapper height="91px">
                <img src={EarnIcon} alt="Earn" height="91" width="91" />
              </IconWrapper>
              <p className="text--primary text--bold">EARN</p>
              <p>
                Collect{' '}
                <strong>
                  1 point
                  <br />
                  for every $1
                </strong>{' '}
                you spend
                <br />
                on participating products.
              </p>
            </Col>
            <Col xs={6} md={3} className="text--center">
              <IconWrapper height="91px">
                <img src={BoostIcon} alt="Boost" height="91" width="91" />
              </IconWrapper>
              <p className="text--primary text--bold">BOOST</p>
              <p>
                Fast track your points
                <br />
                with <strong>special promotions</strong>
                <br />
                throughout the year.
              </p>
            </Col>
            <Col xs={6} md={3} className="text--center">
              <IconWrapper height="91px">
                <img src={EnjoyIcon} alt="Enjoy" height="91" width="91" />
              </IconWrapper>
              <p className="text--primary text--bold">ENJOY</p>
              <p>
                <strong>Redeem your points</strong>
                <br />
                at any time during
                <br />
                the program.
              </p>
            </Col>
          </Row>
        </Grid>
      </LightGrey>
      <Grid>
        <Row justifyContent="center" className="spacer--large">
          <Col className="text--center">
            <a
              href="https://nafda.com.au/find-a-distributor/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DistributorButton>
                Find your local NAFDA Foodservice Distributor
              </DistributorButton>
            </a>
          </Col>
        </Row>
      </Grid>
    </PublicComponent>
  </LoginPageContainer>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
